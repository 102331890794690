
html {
  width: 100% !important;
  overflow-y: scroll; /* Show scrollbars */
  background-color: #212529;
}

label {
  color: whitesmoke;
  font-size: 110%;
}

#code {
  width: 90% !important;
  height: 160px !important;
}
