
html {
  width: 100% !important;
  background-color: #212529;
}

input {
  background-color: #393a3a !important;
  color: whitesmoke !important;
}

textarea {
  background-color: #393a3a !important;
  color: whitesmoke !important;
}

select {
  background-color: #393a3a !important;
  color: whitesmoke !important;
}

#butSave {
  background-color: #007bff !important;
  color: whitesmoke !important;
}

label {
  color: whitesmoke;
  font-size: 110%;
}

img {
  width: 300px;
}

.rcw-header {
  background-color: #212529 !important;
  height: 70px !important;
}

.rcw-messages-container {
  background-color: white !important;
}

.rcw-launcher {
  padding-top: 20px;
  background-color: #212529 !important;
}

.rcw-launcher::before {
  color: whitesmoke;
  font-size: 16px;
  font-weight: normal;
}

.rcw-launcher::after {
  padding-bottom: 30px !important;
  margin-bottom: 15px !important;
}

.rcw-picker-icon {
  width: 1px !important;
}

.rcw-input {
  width: 300px !important;
}

.rcw-send {
  width: 50px !important;
}

.rcw-send-icon {
  width: 50px !important;
  padding-bottom: 10px !important;
}

.hidd {
  visibility: hidden;
}

#line {
  color: whitesmoke !important;
}

#butAddFunction {
  background-color: #212529;
  font-size: 100%;
}

.nav-item .nav-link.active {
  background-color: #212529 !important;
  color: cornflowerblue !important;
  font-size: 80% !important;
}

/* Change the background and text color of the inactive tabs */
.nav-item .nav-link {
  background-color: #212529 !important;
  color: whitesmoke !important;
  font-size: 80% !important;
  border-color: whitesmoke !important;
}

#butAddAPI {
  background-color: #212529;
  font-size: 100%;
}

#butAddField {
  background-color: #212529;
  font-size: 100%;
}

#butAddApiParam {
  background-color: #212529;
  font-size: 100%;
}

.fileInputLabel {
  display: inline-block;
  padding: 10px 15px;
  background-color: #4285f4;
  color: #fff;
  cursor: pointer;
  width: 50px !important;
}

.fileInputLabel input[type="file"] {
  display: none;
  font-size: 80%;
}

#butActiveDeactive {
  outline: none;
  font-size: 100%;
}

#butStress {
  outline: none;
  font-size: 100%;
}

.butAct {
  background-color: #25d366;
  color: whitesmoke;
}

.butDeact {
  background-color: #adb5bd;
  color: whitesmoke;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: auto;
  background-color: rgb(56, 56, 56);
  color: #25d366;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 100;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 90%;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.items-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Adjust the minmax values as per your item size */
  gap: 10px; /* Adjust the spacing between items */
}

.item {
  display: flex;
  border-color: whitesmoke !important;
  border-width: 0.5px;
  border-style: groove;
  align-items: center; /* This aligns the buttons vertically in the center */
  border-radius: 10px; /* Rounds the borders */
  height: 35px;
  margin-bottom: 5px;
}

.btnTransp {
  background-color: transparent !important;
  font-size: 90%;
}

.customBorder {
  display: flex;
  border-color: whitesmoke !important;
  border-width: 0.5px;
  border-style: groove;
  align-items: center; /* This aligns the buttons vertically in the center */
  border-radius: 10px; /* Rounds the borders */
  height: 40px !important;
  margin-bottom: 5px;
}

.btnTransp2 {
  background-color: transparent !important;
  font-size: 90%;
}

