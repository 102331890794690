
html {
  width: 100% !important;
  overflow-y: scroll; /* Show scrollbars */
  background-color: #212529;
}

.record-list {
  height: 100%; 
  overflow-y: auto; 
  padding-right: 20px;
}

.description{
  color: whitesmoke
}

.container {
  padding-left: 10px;
}

@media screen and (min-width: 1024px) {
  .conteudo {
    margin-left: 5%;
    padding-left: 40px;
    width: 95%;
    font-family: verdana;
  }

  .container {
    padding-left: 135px !important;
  }
}

@media screen and (max-width: 600px){

}

.textoFiltro{
  margin-top: 10px;
  margin-bottom: 50px;
  margin-left: 25%;
  font-family: verdana;
  padding-top: 20px;
  width: 70%;
  height:35px;
  line-height:35px;
  padding:0 5px;
}

.butAdd {
  margin-top: 10px;
  margin-bottom: 50px;
  padding-top: 5px !important;
  color: black;
}

.botExcluir {
  border-color: white;
  background-color: white !important;
  color: red !important;
}

.shadow1 {
  -webkit-box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
  -moz-box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
  box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
}

.bottom-fixed {
  left: 0px;
  position: fixed;
  height: 30px;
  bottom: 0px;
  right: 0px;
  margin-bottom: 0px;
  font-weight: bold;
}

#fotoAtleta {
  width: 30px !important;
}
